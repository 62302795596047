import './App.css';

import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';

import './index.css';
import 'firebaseui/dist/firebaseui.css';

import { Summary } from './Summary';
import { PhoneSignIn } from './PhoneSignIn';
import { AuthResult, OAuthUIFactory } from './AuthUI';
import { loadFirebaseConfig, setupFirebaseAuth } from './firebaseSetupHelpers';

export enum AuthView {
  HOME = 'home',
  PHONE_CONFIRMATION = 'phone_confirmation',
  SUMMARY = 'summary',
}

const firebaseConfig = loadFirebaseConfig();
const { firebaseAuth } = setupFirebaseAuth(firebaseConfig);

const OAuthUI = OAuthUIFactory({ firebaseAuth });

export function App() {
  const [error, setError] = React.useState<string>();

  function retryOnError() {
    // not important
    window.location.reload();
  }

  return (
    <div className="App">
      <header className="App-header">
        {error ? (
          <div>
            <strong>{error}</strong>
            <button type="button" className="large-btn" onClick={retryOnError}>
              Retry
            </button>
          </div>
        ) : (
          <AppView setError={setError} />
        )}
      </header>
    </div>
  );
}

function AppView({ setError }: { setError: (error: string) => void }) {
  const [idToken, setIdToken] = React.useState<string>();
  const [providerAccountToken, setProviderAccountToken] =
    React.useState<string>();
  const [authView, setAuthView] = useState(AuthView.HOME);

  const onPhoneSignInSuccess = async (customToken: string) => {
    const userCredentials =
      await firebaseAuth.signInWithCustomToken(customToken);

    if (userCredentials.user) {
      const userTokenId = await userCredentials.user.getIdToken();

      setIdToken(userTokenId);
      setAuthView(AuthView.SUMMARY);
    }
  };

  const signInAnonymously = async () => {
    const anonymous = await firebaseAuth.signInAnonymously();
    const userTokenId = await anonymous.user?.getIdToken();

    setIdToken(userTokenId);
  };

  const onOAuthSignInSuccess = async (authResult: AuthResult) => {
    const haveConfirmedPhoneNumber = authResult.user.providerData.some(
      (info) => info.providerId === 'phone' && Boolean(info.phoneNumber)
    );

    if (haveConfirmedPhoneNumber || authResult.user.isAnonymous) {
      const userTokenId = await authResult.user.getIdToken();

      setIdToken(userTokenId);
      setAuthView(AuthView.SUMMARY);
    } else {
      await signInAnonymously();
      setProviderAccountToken(await authResult.user.getIdToken());
      setAuthView(AuthView.PHONE_CONFIRMATION);
    }
  };

  switch (authView) {
    case AuthView.HOME:
      return (
        <div className="App-home">
          <div>
            <button
              className="sign-in-btn"
              onClick={async () => {
                await signInAnonymously();
                setAuthView(AuthView.PHONE_CONFIRMATION);
              }}
            >
              Sign in with phone
            </button>
            <OAuthUI
              onError={setError}
              onProviderSignInSuccess={onOAuthSignInSuccess}
            />
          </div>
          <SuperloginIDConverter />
        </div>
      );
    case AuthView.PHONE_CONFIRMATION:
      return (
        <PhoneSignIn
          onSuccess={onPhoneSignInSuccess}
          idToken={idToken}
          providerAccountToken={providerAccountToken}
        />
      );
    case AuthView.SUMMARY:
      return <Summary token={idToken || ''} />;
  }
}

const maxAllowedId = 9223372036854775807n // 2n ** 63n - 1n;
const minAllowedId = -maxAllowedId;

function SuperloginIDConverter() {
  const [superloginToken, setSuperloginToken] = React.useState<string>('');
  const [superloginId, setSuperloginId] = React.useState<string>('');
  const [superloginIdBigInt, setSuperloginBigInt] = React.useState<bigint>(0n);

  const setUserToken = (token: string) => {
    try {
      const decodedToken = atob(token.split('.')[1]);
      const tokenData = JSON.parse(decodedToken);
      if (!tokenData.sid) {
        throw new Error(
          "Unexpected token - GraphQL token should include 'sid' value"
        );
      }
      setUserId(tokenData.sid);
      setSuperloginToken(token);
    } catch (err) {
      console.warn(err);
      setSuperloginToken('INVALID_TOKEN');
      setUserId('');
    }
  };

  const setUserId = (id: string) => {
    try {
      if (id.length !== 11) {
        throw new Error('Invalid Superlogin ID, it should have 11 chars');
      }

      const bigintId = Buffer.from(id, 'base64').readBigInt64BE();
      setSuperloginId(id);
      setSuperloginBigInt(bigintId);
      setSuperloginToken('');
    } catch (err) {
      setSuperloginId('INVALID_ID');
      setUserIdBigInt(0n);
      setSuperloginToken('');
      console.warn(err);
    }
  };

  const setUserIdBigInt = (bigintId: bigint) => {
    try {
      if (bigintId < minAllowedId || bigintId > maxAllowedId) {
        throw new Error(
          `Super account id out of range, can not continue ${bigintId}`
        );
      }
      const buffer = Buffer.alloc(8);
      buffer.writeBigInt64BE(bigintId);

      const id = normalizeBase64Id(buffer.toString('base64'));

      if (id.length !== 11) {
        throw new Error('Invalid Superlogin ID, it should have 12 chars');
      }

      setSuperloginId(id);
      setSuperloginBigInt(bigintId);
      setSuperloginToken('');
    } catch (err) {
      setSuperloginId('INVALID_ID');
      setSuperloginToken('');
      setSuperloginBigInt(0n);
      console.warn(err);
    }
  };

  return (
    <div className="App-home-ID">
      <div className="input-container">
        <label>Superlogin token</label>
        <input
          type="text"
          value={superloginToken}
          onChange={(e) => setUserToken(e.target.value.trim())}
        />
      </div>

      <div className="input-container">
        <label>Superlogin ID Base64</label>
        <input
          type="text"
          value={superloginId}
          onChange={(e) => setUserId(e.target.value.trim())}
        />
      </div>

      <div className="input-container">
        <label>Superlogin ID BigInt</label>
        <input
          type="text"
          value={String(superloginIdBigInt)}
          onChange={(e) => setUserIdBigInt(BigInt(e.target.value.trim()))}
        />
      </div>
    </div>
  );
}

function normalizeBase64Id(id: string) {
  return id.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
